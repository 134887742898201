import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import herobanner from "../assets/images/banners/blog-banner.jpg";

import BlogCard from "../components/Blog/blog-card";
import HeroHeader from "../components/Blog/hero-header";

export const getPosts = graphql`
  query News {
    posts: allContentfulBlog(filter: { pageType: { eq: "Blog" } }) {
      edges {
        node {
          id
          title
          date(formatString: "DD MMM YYYY")
          slug
          pageType
          featuredImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const Blog = () => {
  const { posts } = useStaticQuery(getPosts);

  return (
    <Layout>
      <Hero bgImage={herobanner} title="News & Blog from Global Mission Desk" />

      <section className="container p-4 w-full mx-auto lg:px-8 lg:py-10">
        <div>
          <HeroHeader
            title1="News & Blog"
            tagLine1="Ministry News and updates from the Director"
            desc="Get udpated with the news from our associate partners & Churches, ministerial events aross the globe."
          />
          <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-2 gap-4">
            {posts.edges.map(({ node }) => (
              <div>
                <BlogCard
                  title={node.title}
                  date={node.date}
                  link={node.slug}
                  image={node.featuredImage.file.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Blog;
